
@import "colors";

.shelter-timeline-outer {
	display: flex;
	padding-top: 10px;
}

.shelter-timeline-button {
	width: 30px;
	padding: 3px;
	text-align: center;
	position: relative;
}

.shelter-timeline-button .fa {
	position: absolute;
	left: 50%;
	margin-left: -7px;
	top: 50%;
	margin-top: -50%;
}

.shelter-timeline-button-left {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.shelter-timeline-button-right {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	order: 3;
	
}
.shelter-timeline-content {
	flex: 1;
	order: 2;
	border: 1px solid #888;
	position: relative;
	padding-bottom: 20px;
}

.shelter-timeline-title {
	position: relative;
	text-align: center;
	padding: 2px;
	z-index: 8;
	background: rgba(255,255,255,.8);
}

.shelter-timeline-line {
	height: 32px;
	margin: 5px 0;
	position: relative;
}

.shelter-timeline-status {
	border: 1px solid $animal-color-adoption;
	background: #d0ffc7;
	overflow: hidden;
	z-index: 10;
	position: absolute;
	top: 0;
	height: 100%;
	
	&:hover {
		overflow: visible;
		z-index: 11;
		
		.animal-item-small {
			width: auto;
		}
	}
	
	.animal-item-small {
		position: relative;
		border: none;
		margin: 0;
		height: 100%;
		background: inherit;
		min-width: 100%;
		width: 100%;
	}

	&.shelter-timeline-status-pension {
		border-color: $animal-color-pension;
		background: #c0d2ff;
	}
	
	&.shelter-timeline-status-fake {
		border-color: $animal-color-highlight;
		background: #ffae98;
	}
	
	&.shelter-timeline-status-fake,
	&.shelter-timeline-status-planned {
		border-style: dashed;
		opacity: .8;
	}
}




.shelter-timeline-days {
	overflow: hidden;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.shelter-timeline-day {
	position: absolute;
	top: 0;
	bottom: 0;
}

.shelter-timeline-content-month .shelter-timeline-day,
.shelter-timeline-content-week .shelter-timeline-day {
	border-right: 1px dotted #888;
}

.shelter-timeline-weekday-0, .shelter-timeline-weekday-6 {
	background: rgb(190, 190, 190);
}

.shelter-timeline-current {
	border-left: 2px solid blue;
	margin-left: -1px;
	position: absolute;
	top: 0;
	bottom: 0;
}

.shelter-timeline-day-label {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	color: #444;
	z-index: 15;
}
.shelter-timeline-day-text {
	display: inline-block;
	background: rgba(255, 255, 255, .8);
}

.shelter-timeline-weekday-0 .shelter-timeline-day-text, .shelter-timeline-weekday-6 .shelter-timeline-day-text {
	background: rgba(190, 190, 190, .8);
}
.pension-edit-category {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.pension-animal-associate {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.pension-animal-associate-data {
	flex: 1;
}

.pension-animal-associate-arrow {
	width: auto;
	margin: 20px;
}

.pension-animal-associate-animal {
	flex: 1;
	.fa-question {
		font-size: 800%;
		opacity: .6;
	}
}
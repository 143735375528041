body {
	background: #eef;
}

.navbar {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       position: relative;
       z-index: 1002;
       align-items: flex-end;
}

.main-title {
	display: flex;
	flex-direction: column;
	padding-left: 70px;
	height: 60px;
	font-size: 120%;
	text-decoration: none;
	color: #333;
	
	background-image: url(./logo-tierverwaltung-sm.png);
	background-position: center left;
	background-repeat: no-repeat;
	background-size: contain;
	
	&:hover,
	&:active {
		text-dectoration: none;
		color: #000;
	}
}

.title-sub {
	font-size: 80%;
}

@include media-breakpoint-down(sm) {
	.title-name,
	.title-sub {
		display: none;
	}
}


.main-search {
	flex: 0 1 400px;
}

#main-outer {
	display: flex;
	position: relative;
}

#main-sidebar {
	padding: 0 10px 20px;
	max-width: 240px;
	flex: 0 0 220px;
	background: #fff;
	border-right: 1px solid #aaa;

	.sidebar-title {
		margin-top: 30px;
	}
	
	.sidebar-item {
		padding: 2px 5px;
		display: block;
		color: #333;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		position: relative;
	}
	
	.sidebar-item:hover {
		background: #eee;
		color: #000;
	}
	
	.sidebar-item .tag {
		margin-top: 2px;
	}
	
	#main-sidebar a.dropdown-item {
		text-overflow: ellipsis;
		overflow:hidden;
	}
	
	@include media-breakpoint-down(sm) {
		&.in {
			display: block !important;
			position: absolute;
			left: 0;
			width: 250px;
			max-width: 100%;
			border: 1px solid #aaa;
			z-index: 1002;
		}
		
	}
}

@include media-breakpoint-down(sm) {
	#main-sidebar-bg.in {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		opacity: 0.5;
		z-index: 1001;
	}
}

#main-container {
	padding: 20px 15px 0;
	flex: 1 1 auto;
	width: auto;
	min-width: 0;
}

h1 .btn-outline-success {
	background: #fff;
}


.card-section:hover {
	box-shadow: 0 0 8px #888;
}

.page-content {
	.card-root, .card-section {
		margin-bottom: 20px; 
	}
	
	@include media-breakpoint-down(sm) {
		.card-root,
		.card-root .card-section {
			border-radius: 0;
			border-left: none;
			border-right: none;
		}
		
		.card-root {
			margin-left: -15px;
			margin-right: -15px;
			box-shadow: none;
		}
		
		.card-root .card-section {
			margin-left: -1.25rem;
			margin-right: -1.25rem;
			box-shadow: 0 0 8px #888;
		}
		
		.card-root .card-section.card-animal {
			margin-left: auto;
			margin-right: auto;
		}
	
		> .container {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.modal-footer-space {
	flex: 1;
}


.animal-item {
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	
	border: 1px solid #888;
	margin: 1px;
	padding: 0 5px 0 0;
	flex-direction: row;
	align-items: center;
	
	@include border-radius($border-radius);
	
	.animal-item-image-link {
		display: flex;
	}
	.animal-item-image {
		width: 80px;
		height: 60px;
		margin-right: 10px;
		@include border-left-radius($border-radius);
	}
	
	.animal-item-description {
		min-width: 20px;
	}
	
	.animal-item-name {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	
	.animal-item-details {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.animal-item-small {
	 .animal-item-image {
		width: 40px;
		height: 30px;
		margin-right: 5px;
	}
	
	.animal-item-name {
		position: relative;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 10px;
	}
}

.animal-item-inline {
	display: inline-flex;
	min-width: 20px;
}

.animal-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.animal-item-default {
	margin: 5px;
	
	.animal-item-image {
		position: relative;
		width: 120px;
		height: 90px;
	}
	
	.animal-item-description {
		max-width: 250px;
	}
	
	@include media-breakpoint-up(md) {
		flex-direction: column;
		align-items: flex-start;
		width: 240px;
		
		.animal-item-image-link {
			display: flex;
		}
		
		.animal-item-description {
			padding: 10px;
		}
		
		.animal-item-image {
			margin: 0;
			width: 240px;
			height: 180px;
			@include border-top-radius($border-radius);
			border-bottom-left-radius: 0;
		}
	}
}

.animal-status {
	display: flex;
	flex-direction: row;
	padding: 5px 0;
	
	.animal-status-animal {
		flex: 50% 1;
		min-width: 200px;
		
		.animal-item-inline {
			display: flex;
			border: none;
		}
	}
	.animal-status-icon {
		margin: 0 10px;
	}
	.animal-status-description {
		flex: 50% 1;
		min-width: 200px;
	}
}

.animal-card-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.animal-card {
	overflow: hidden;
	max-width: 100%;
	width: 500px;
	display: flex;
	flex-direction: row;
	align-items: center;
	
	margin-bottom: 10px;
	
	h1 {
		font-size: 200%;
	}
	.animal-card-image {
		flex: 0 160px;
		width: 160px;
		height: 120px;
		margin-right: 10px;
	}
	.animal-card-data {
		flex: 1;
		min-width: 0;
		
		> h1,
		> div {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	
}

.animal-nav-space {
	flex: 1;
}

.selectanimals-result {
	display: flex;
}

.selectanimals-result .animal-item-inline {
	flex: 1;
}

.selectanimals-disabled {
	opacity: .5;
}

.selectanimals-select {
	flex: 0 0 auto;
}
.shelter-layout-base {
	position: relative;
	
	.shelter-layout-image {
		width: 100%;
		height: auto;
	}
	
	.shelter-layout-box {
		position: absolute;
		
		width: 150px;
		margin-left: -75px;
		margin-top: -20px;
		padding: 5px;
		
		border: 1px solid #888;
		background: rgba(255,255,255, .5);
	}
	
	.shelter-layout-box-name {
		font-size: 110%;
		font-weight: bold;
		text-align: center;
	}
}
/*
Make bootstrap-select work with bootstrap 4 see:
https://github.com/silviomoreto/bootstrap-select/issues/1135
*/
.dropdown-toggle.btn-default {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc;
}

.bootstrap-select.show>.dropdown-menu>.dropdown-menu {
  display: block;
}

.bootstrap-select > .dropdown-menu > .dropdown-menu li.hidden{
  display:none;
}

.bootstrap-select > .dropdown-menu > .dropdown-menu li a{
  display: block;
  width: 100%;
  padding: 3px 1.5rem;
  clear: both;
  font-weight: 400;
  color: #292b2c;
  text-align: inherit;
  white-space: nowrap;
  background: 0 0;
  border: 0;
}

.dropdown-menu > li.active > a {
  color: #fff !important;
  background-color: #337ab7 !important;
}

/* Make filled out selects be the same size as empty selects */
.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline !important;
}
.upload-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	user-select: none;
	-webkit-touch-callout: none;
	
	@mixin upload-item() {
		width: 320px;
		height: 240px;
		margin: 10px;
		position: relative;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
	}
	
	.upload-entry {
		@include upload-item();
		.upload-image {
			position: absolute;
			left: -3px;
			top: -3px;
			bottom: -3px;
			right: -3px;
		
			background-size: cover;
			background-clip: padding-box;
			background-repeat: none;
			@include border-radius(10px);
			border: 3px solid transparent;
		}
		
		&.dragging {
			opacity: .6;
			.upload-image {
				border-style: dashed;
				border-color: #888;
			}
		}
		
		&.uploading {
			opacity: .6;
		}
		
		.upload-error {
			width: 100%;
			height: 100%;
			@include border-radius(10px);
			background: rgba(155, 0, 0, .5);
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
		}
		
		.progress {
			width: 90%;
		}
	}
	
	.upload-add {
		@include upload-item();
		
		.upload-add-image {
			width: 80%;
			height: 80%;
			font-size: 120px;
			@include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
			color: #888;
			@include border-radius($border-radius);
			border: $input-btn-border-width solid transparent;
			@include transition($btn-transition);
			
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
			position: relative;
			
			.upload-add-plus {
				position: absolute;
				bottom: 20px;
				right: 30px;
				font-size: 50px;
				color: #444;
				@include transition($btn-transition);
			}
			
			&:hover .upload-add-plus {
				color: #000;
			}
		}
		
	}
	
	@mixin image-icon-base() {
		position: absolute;
		
		font-size: 30px;
		color: #ccc;
		cursor: default;
		opacity: .8;
		
		.fa-circle {
			opacity: .8;
			color: #333;
		}
	}
	
	.image-icons {
		position: absolute;
		top: 0;
		right: 0;
		background: rgba(255, 255, 255, .3);
		cursor: default;
		@include border-radius(7px);
	}
	
	.upload-error-close {
		@include image-icon-base();
		right: 5px;
		top: 5px;
		
		&:hover {
			opacity: 1;
		}
	}
	
	.upload-reorder {
		@include image-icon-base();
		right: 5px;
		bottom: 5px;
		cursor: move;
		
		&:hover {
			opacity: 1;
		}
		
		.fa-circle {
			color: #333;
		}
	}
	
	.upload-select {
		@include image-icon-base();
		top: 5px;
		left: 5px;
		
		.fa-square {
			color: #333;
		}
		
		.fa-check-square-o {
			opacity: 0;
		}
		
		&:hover .fa-check-square-o {
			opacity: 0.2;
		}
	}
	
	.upload-entry.selected {
		border-color: $brand-primary;
		background: $brand-primary;
		
		.upload-image {
			border-color: $brand-primary;
			opacity: .8;
		}
	
		.upload-select {
			.fa-check-square-o {
				opacity: 1;
				color: $brand-primary;
			}
			.fa-square-o {
				display: none;
			}
		}
	}
	
	.uploading-blueprint {
		display: none;
	}
}

.image-preview {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-height: 300px;
	overflow-y: auto;
	
	.image-preview-item {
		position: relative;
		width: 160px;
		height: 120px;
		background-size: cover;
		@include border-radius(5px);
		margin: 5px;
	}
}

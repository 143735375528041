
.adoption-status-animals {
	.animal-formitem-outer {
		display: flex;
		flex-direction: row;
		margin-bottom: 5px;
	}
	
	.animal-item {
		flex: 1;
	}
}

// Fonts
@import "../../../node_modules/font-awesome/css/font-awesome.css";

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap.scss";

.form-control {
	min-width: 20px;
}

@import "layout";
@import "animals";
@import "selectanimals";
@import "timeline";
@import "uploadimages";
@import "adoption";
@import "shelter";
@import "pension";
@import "fix-bootstrap-select";